import React from 'react';
import { seoContext } from 'context/seo-context';
import Layout from 'components/Layout';
import { useLocation } from '@reach/router';
import { getNewsletterSegmentFromURL, getStringValueBySegment, NewsletterSegment, ValueType } from 'model/freeUpdates';

import { SimpleNewsletterForm } from 'components/SimpleNewsletterForm';

interface FreeUpdatesBodyProps {
  segment: NewsletterSegment;
}
const FreeUpdatesCopy: React.FC<FreeUpdatesBodyProps> = (props) => {
  switch (props.segment) {
    case NewsletterSegment.InterviewProcessDiagram:
      return (
        <>
          <p>
            Have you ever been in a technical interview where you just stared at the whiteboard (or text editor) not
            knowing what to do and just...hoped for the best?
          </p>
          <p>
            I was there back in 2015 and it didn&apos;t go very well. The experience sucked so much that I spent the
            next several years creating an interview process I could use to maximize my chances of getting a job.
          </p>
          <p>
            Today, I want to share my work with you. Enter your name and email below and I&apos;ll email you
            Cadejo&apos;s 6-step interview process. Let&apos;s do this!
          </p>
        </>
      );
    case NewsletterSegment.YoutubeJsStrings:
      return (
        <>
          <p>
            Hey! I&apos;m happy you liked my video about Javascript strings, and I&apos;m happier you are investing your
            time in becoming a better developer!
          </p>
          <p>Let me help you with your Javascript journey! Start by filling the form below:</p>
        </>
      );
    case NewsletterSegment.Default:
    default:
      return <p>Let me help you with your coding journey! Start by filling the form below:</p>;
  }
};

const FreeUpdates: React.FC = () => {
  const location = useLocation();

  let segment = NewsletterSegment.Default;
  if (location.search) {
    segment = getNewsletterSegmentFromURL(location.search);
  }
  return (
    <seoContext.Provider value={{ title: getStringValueBySegment(ValueType.Title, segment) }}>
      <Layout>
        <article>
          <div className="inner">
            <div className="section section-content">
              <div className="section-head">
                <div className="container">
                  <h1 className="section-title">{getStringValueBySegment(ValueType.Title, segment)}</h1>
                </div>
              </div>
              <div className="section-body content">
                <div className="container">
                  <div className="center">
                    <div>
                      <FreeUpdatesCopy segment={segment} />
                    </div>
                  </div>
                  <div className="subscribe">
                    <SimpleNewsletterForm segment={segment} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </Layout>
    </seoContext.Provider>
  );
};

// This has to be a default export for gatsby to be able to pick it up
export default FreeUpdates;
